<template>
	<section class="myrooms-section">
		<div class="myrooms">
			<div class="myrooms__title-wrapper">
				<div class="myrooms__title-wrapper__left">
					<h2 class="myrooms__title">Мое жильё</h2>
					<router-link to="/place/create" @click="clearCreatePlaceData" class="btn addroom-btn">
						Добавить жильё
					</router-link>
				</div>
				<!-- <div class="myrooms__title-wrapper__right">
					<button class="btn title-btn all-btn is-active notification">
						Всё<span class="rooms-quantity">(99)</span>
					</button>
					<button class="btn title-btn rent-btn">
						Сдается<span class="rooms-quantity">(55)</span>
					</button>
					<button class="btn title-btn norent-btn notification">
						Не сдается<span class="rooms-quantity">(55)</span>
					</button>
				</div> -->
			</div>
			<ul v-if="data.items && data.items.length" class="rooms">
				<Housing
					v-for="item in data.items"
					:key="item.place_id"
					:content="item"
					@update="get"
				/>
			</ul>
			<p v-else class="myrooms__zero-text">
				У вас ещё нет жилья.
			</p>

			<tk-pagination
				v-if="data.meta"
				:offsetPage="page"
				:content="data.meta"
				@next="changePage"
			/>
		</div>
	</section>
</template>

<script>
import Housing from '@/components/cards/Housing'
import { CLEAR } from '@/constants/store/place/mutations'
import { api } from '@/plugins/api'

export default {
	components: { Housing },
	data() {
		return {
			data: {},
			page: 1,

      getAbortController: new AbortController(),
		}
	},
	methods: {
		async get() {
      this.getAbortController.abort()
      this.getAbortController = new AbortController()
			const response = await api.place.getPlacesOwner({ page: this.page }, {controller: this.getAbortController})

			if (response.status) {
				this.data = response.response
			}
		},
		changePage(payload) {
			this.page = payload
		},
		clearCreatePlaceData() {
			this.$store.commit(CLEAR)
		}
	},
	async beforeMount() {
		await this.get()
	},
  beforeUnmount() {
    this.getAbortController.abort()
  },
  watch: {
		async page() {
			await this.get()
		}
	}
}
</script>

<style scoped lang="sass">
.addroom-btn
	display: flex
	justify-content: center
	align-items: center
	width: 160px
	height: 35px
	text-decoration: none
	color: white
	font-style: normal
	font-weight: 600
	font-size: 16px
	line-height: 16px
	text-align: center
	background-color: $green
	border-radius: 11px
	box-shadow: 0px 10px 36px rgba(95, 83, 80, 0.2), 0px 10px 36px rgba(0, 0, 0, 0.2)
.edit-btn
	position: absolute
	top: 19px
	left: 15px
	font-weight: bold
	font-size: 12px
	line-height: 12px
	&:after
		content: url('~@/assets/images/svg/edit.svg')
		margin-left: 9px
	&:hover
		&.edit-btn::after
			content: url('~@/assets/images/svg/edit-green.svg')
.myrooms-section
	margin-top: -30px
	background-color: #ffffff
	padding-bottom: 251px
	box-sizing: border-box
	.myrooms
		position: relative
		width: 970px
		&__title-wrapper
			display: flex
			justify-content: space-between
			align-items: center
			margin-bottom: 30px
			&__left
				display: flex
				justify-content: space-between
				align-items: center
				width: 32%
			&__right
				display: flex
				justify-content: space-between
				width: 50%
		&__title
			font-weight: bold
			font-size: 24px
			line-height: 24px
			&__card
				position: relative
				max-width: 310px
				list-style: none
				&.not-active
					.switch-btn
						border: 1px solid #1AC386
						border-radius: 12px
						background-color: #fff
						color: #1AC386
						box-shadow: none
				.description-wrapper
					background: #FFFFFF
					border: 0.5px solid #E2E2E2
					box-sizing: border-box
					border-radius: 10px
					margin-bottom: 20px
				&__img
					width: 100%
					height: 170px
					border-bottom: 1px solid #CBCBCB
					border-radius: 10px
					background: url('~@/assets/img/svg/camera-white.svg') center no-repeat #CBCBCB
					background-size: 65px
					img
						height: 100%
						width: 100%
						border-radius: 10px
				&__text
					padding: 10px 15px 24px
				&__dates
					font-weight: 600
					font-size: 12px
					line-height: 12px
					span
						font-weight: normal
						font-size: 12px
						line-height: 12px
						color: #C0AEA7
				&__publication
					margin-bottom: 5px
				&__period
					margin-bottom: 15px
				&__name
					display: flex
					justify-content: space-between
					margin-bottom: 15px

					font-weight: 600
					font-size: 16px
					line-height: 16px

					overflow: hidden
				&__progress
					display: flex
					justify-content: space-between
					flex-wrap: wrap
					font-weight: 600
					font-size: 12px
					line-height: 12px
				&__btns
					display: flex
					justify-content: space-between

// 1080
@media screen and (max-width: 1440px)
	.myrooms-section
		.myrooms
			width: 794px
			&__title-wrapper
				&__left
					width: 38%
				&__right
					width: 60%

// 996
@media screen and (max-width: 1180px)
	.myrooms-section
		.myrooms
			width: 726px
			&__title-wrapper
				flex-wrap: wrap
				&__left
					width: 46%
					margin-bottom: 20px
				&__right
					width: 70%

// 900
@media screen and (max-width: 1024px)
	.myrooms-section
		.myrooms
			width: 651px
			&__title-wrapper
				&__left
					width: 50%
				&__right
					width: 100%

// 768
@media screen and (max-width: 912px)
	.myrooms-section
		.myrooms
			width: 538px
			&__title-wrapper
				&__left,
				&__right
					width: 100%
// 540
@media screen and (max-width: 800px)
	.myrooms-section
		.myrooms
			width: 100%
			&__title-wrapper
				&__right
					button
						width: 100px
						font-size: 12px
						line-height: 12px
						span
							display: none
</style>
