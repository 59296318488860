<template>
	<li class="rooms__card housing-card">
		<a v-if="isShowEditButton" @click="editAds" class="btn edit-btn">
			Редактировать
		</a>

		<router-link :to="`/place/${content.place_id}?type=self`" class="card-link housing-card__link-container">
			<div class="description-wrapper">
				<div class="rooms__card__img">
					<tk-image
						v-if="content.images.length"
						:src="content.images[0]?.image"
						height="170"
						width="310"
						alt="Фото жилья"
					/>
					<!-- <div v-else class="rooms__card__img" /> -->
					<span
						@click.prevent.stop="showReviewRejectedReasonModal"
						:class="['rooms-card__status', status.class]"
					>
						{{ status.title }}
						<img
							v-if="status.icon"
							src="@/assets/img/icons/warn-info.svg"
							width="16"
							height="16"
							alt="info"
							class="rooms-card__status--icon"
						/>
					</span>
				</div>
				<div class="rooms__card__text">
					<span class="price">
            {{ content.price_type != 'place' ? 'От' : ''}}
            {{ content.price }} ₽
          </span>
					<!-- <p class="rooms__card__dates rooms__card__publication">
						Опубликовано: <span>{{ getDate(content.created_at) }}</span>
					</p> -->
					<!-- <p class="rooms__card__dates rooms__card__period">
						Период сдачи: <span>20 апр 2021 - 12 мая 2022</span>
					</p> -->
					<p class="rooms__card__name">
						{{ content.title }}
					</p>
					<div class="rooms__card__progress">
						<p>Объявление заполнено</p>
						<span>{{ content.filled_percentage }}%</span>
						<svg width="280" height="6">
							<line
								x1="4"
								y1="4"
								x2="275"
								y2="4"
								stroke="#F0F0F0"
								stroke-width="4"
								stroke-linecap="round"
							/>
							<!-- значение аттрибута x2 является процентом заполнения от 1 до 99%-->
							<line
								class="progress-bar"
								x1="4"
								y1="4"
								:x2="(content.filled_percentage * 275) / 100"
								y2="4"
								stroke="#1AC386"
								stroke-width="4"
								stroke-linecap="round"
							/>
						</svg>
					</div>
				</div>
			</div>
		</router-link>

		<div class="rooms__card__btns">
			<button
				v-if="isShowSendToVerificationButton"
				class="btn roomcard-btn switch-btn"
				@click.prevent="sendToVerify"
			>
				Проверить
			</button>
			<button
				v-else
				@click.prevent="toggleShowAds"
				:disabled="isDisabledToggleShowButton"
				class="btn roomcard-btn switch-btn"
			>
				{{ content.is_publicated ? 'Выключить' : 'Включить' }}
			</button>

			<button @click.prevent="deleteAds" class="btn roomcard-btn del-btn">
				Удалить
			</button>
		</div>
	</li>
</template>

<script>
import { SET } from '@/constants/store/place/mutations'
import { getDateInFormat } from '@/utils/dateTime'

import modals from '@/constants/modals'
import verificationStatusPlace from '@/constants/verificationStatusPlace'

export default {
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		isPublicated() {
			return this.content.is_publicated
		},
		isShowSendToVerificationButton() {
			return this.content.verification_status === 0
		},
		isDisabledToggleShowButton() {
			return [2, 4].includes(this.content.verification_status)
		},
		isShowEditButton() {
			return (
				[0, 1, 4, 5].includes(this.content.verification_status)
			)
		},
		status() {
			return verificationStatusPlace[this.content.verification_status]
		}
	},
	methods: {
		getDate(payload) {
			return getDateInFormat(payload)
		},
		async showReviewRejectedReasonModal() {
			const verifStatus = this.content.verification_status

			if (verifStatus !== 4 && verifStatus !== 5) {
				return
			}

			const response = await this.$api.place.getReviewRejectedReason({
				place_id: this.content.place_id
			})

			if (response.status) {
				this.$store.commit('showModal', {
					name: modals.COMMON_INFO,
					props: {
						text: response.response.message
					},
					isShow: true
				})
			}
		},
		toggleShowAds() {
			this.$store.commit('showModal', {
				isShow: true,
				name: modals.CONFIRM_TOGGLE_ADS,
				props: {
					title: this.content.title,
					isOn: this.isPublicated ? false : true,
					callback: async () => {
						const response = await this.$api.place.toggle({
							place_id: this.content.place_id,
							is_show: this.isPublicated ? false : true
						})

						if (response.status) {
							this.$store.commit('showNotification', {
								type: 'success',
								text: this.isPublicated
									? 'Объявление выключено'
									: 'Публикация прошла успешно'
							})

							this.$emit('update')
						} else {
							this.$store.commit('showNotification', { type: 'error', text: 'Ошибка' })
						}
					}
				}
			})
		},
		deleteAds() {
			this.$store.commit('showModal', {
				isShow: true,
				name: modals.CONFIRM_DELETE_ADS,
				props: {
					title: this.content.title,
					callback: async () => {
						const response = await this.$api.place.delete({
							place_id: this.content.place_id
						})

						if (response.status) {
							this.$store.commit('showNotification', { type: 'success', text: 'Удаление прошло успешно' })
							this.$emit('update')
						} else {
							this.$store.commit('showNotification', { type: 'error', text: 'Ошибка' })
						}
					}
				}
			})
		},
		editAds() {
			const { commit } = this.$store

			commit(SET, { key: 'place_id', value: this.content.place_id })
			commit(SET, { key: 'current', value: 1 })
			commit(SET, { key: 'verification_status', value: this.content.verification_status })

			// setTimeout(() => {
				this.$router.push({ name: 'Place.Create' })
			// })
		},
		async sendToVerify() {
			if (this.content.is_draft) {
				return
			}

			const response = await this.$api.place.sendToVerification({
				place_id: this.content.place_id
			})

			if (response.status) {
				this.$store.commit('showNotification', {
					type: 'success',
					text: 'Успешно отправлено на модерацию'
				})
				this.$emit('update')
			} else {
				this.$store.commit('showNotification', {
					type: 'error',
					text: response.errors.message ?? 'Ошибка отправки на модерацию'
				})
			}
		}
	}
}
</script>

<style lang="sass">
.housing-card
  .edit-btn
    position: absolute
    top: 10px
    left: 10px
    display: flex
    justify-content: center
    align-items: center
    width: 125px
    height: 25px
    padding-bottom: 3px
    color: white
    font-weight: 600
    font-size: 12px
    z-index: 2
    background-color: $green
    border-radius: 6px
    
    &:after
      content: ''

      width: 15px
      height: 15px
      margin-right: -6px
      margin-left: 4px

      background-image: url('~@/assets/images/svg/edit-icon-white.svg')
      background-repeat: no-repeat
      background-position: 0 0
      background-size: 15px 15px

  .roomcard-btn
    width: 140px
    height: 35px
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 16px
    color: white
    border-radius: 11px
    background-color: $green

    &.del-btn
      background: #5F5350

  &__link-container
    text-decoration: none
    color: black
</style>