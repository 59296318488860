export default {
  0: {
    title: 'Не опубликовано',
    class: 'not-publicate'
  },
  1: {
    title: 'Объявление одобрено',
    class: 'success'
  },
  2: {
    title: 'Объявление на проверке',
    class: 'under-review'
  },
  3: {
    title: 'Изменения на проверке', // after edit
    class: 'under-review'
  },
  4: {
    title: 'Объявление отклонено',
    icon: true,
    class: 'review-rejected'
  },
  5: {
    title: 'Изменения отклонены', // after edit
    icon: true,
    class: 'review-rejected'
  },
}